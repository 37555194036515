import React, { useEffect } from 'react'

import './App.css';



function App() {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace('https://www.facebook.com/Serw%C3%B3z-109195274117515')
    }, 4000)
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src="/logo.png" className="App-logo" alt="logo" />
        <p>Mateusz Serwa, imprezy okolicznościowe, catering <br />Rakoniewice, Drzymałowo, Wolsztyn</p>
        <a
          className="App-link"
          href="https://www.facebook.com/Serw%C3%B3z-109195274117515"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/fb.png" className="fb-logo" alt='fb' />
        </a>
      </header>
    </div>
  );
}

export default App;
